"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.MODULE_ORDER = exports.MODULE_MONITORING = exports.MODULE_PROSPECTING = exports.MODULE_ANALYZING = exports.MODULE_SAFETY = void 0;
exports.MODULE_SAFETY = 'Sécuriser';
exports.MODULE_ANALYZING = 'Analyser';
exports.MODULE_PROSPECTING = 'Prospecter';
exports.MODULE_MONITORING = 'Surveiller';
exports.MODULE_ORDER = (_a = {},
    _a[exports.MODULE_SAFETY] = 1,
    _a[exports.MODULE_ANALYZING] = 2,
    _a[exports.MODULE_PROSPECTING] = 3,
    _a[exports.MODULE_MONITORING] = 4,
    _a);
