"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.COPRO_TYPE_FILTER_SUBCONTRACTOR_FOR_COPRO = exports.COPRO_TYPE_FILTER_SUBCONTRACTED_COPRO = exports.COPRO_TYPE_FILTER_COMPANY_COPRO = exports.coproTypes = void 0;
exports.coproTypes = {
    1: 'Copro',
    2: 'ASL',
    3: 'Immeuble tertiaire',
    4: 'AFUL',
    5: 'Autre',
};
exports.COPRO_TYPE_FILTER_COMPANY_COPRO = 'Mes copropriétés';
exports.COPRO_TYPE_FILTER_SUBCONTRACTED_COPRO = 'Mes copropriétés sous-traitées';
exports.COPRO_TYPE_FILTER_SUBCONTRACTOR_FOR_COPRO = 'Copropriétés dont je suis le sous-traitant';
