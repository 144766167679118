"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var JWTHeader_1 = require("../../../../utils/JWTHeader");
var flowbite_react_1 = require("flowbite-react");
var CompanySearch = function (_a) {
    var onCompanySelect = _a.onCompanySelect;
    var _b = (0, react_1.useState)(''), searchQuery = _b[0], setSearchQuery = _b[1];
    var _c = (0, react_1.useState)([]), companies = _c[0], setCompanies = _c[1];
    var _d = (0, react_1.useState)(false), loading = _d[0], setLoading = _d[1];
    var _e = (0, react_1.useState)(false), showDropdown = _e[0], setShowDropdown = _e[1];
    var _f = (0, react_1.useState)(null), selectedCompany = _f[0], setSelectedCompany = _f[1];
    var jwt = (0, react_redux_1.useSelector)(function (state) { return state.auth.token; });
    var inputRef = (0, react_1.useRef)(null);
    var fetchCompanies = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, data, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 6, 7, 8]);
                    return [4 /*yield*/, fetch("/api/companies?step=1&limit=10&filterSearch=".concat(searchQuery), (0, JWTHeader_1.JWTHeader)(jwt))];
                case 2:
                    response = _a.sent();
                    if (!response.ok) return [3 /*break*/, 4];
                    return [4 /*yield*/, response.json()];
                case 3:
                    data = _a.sent();
                    setCompanies(data.results);
                    setShowDropdown(true);
                    return [3 /*break*/, 5];
                case 4:
                    console.error('Failed to fetch companies');
                    _a.label = 5;
                case 5: return [3 /*break*/, 8];
                case 6:
                    error_1 = _a.sent();
                    console.error('Error fetching companies:', error_1);
                    return [3 /*break*/, 8];
                case 7:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 8: return [2 /*return*/];
            }
        });
    }); };
    (0, react_1.useEffect)(function () {
        if (searchQuery.length > 2 && !selectedCompany) {
            var debounceTimer_1 = setTimeout(function () {
                fetchCompanies();
            }, 300);
            return function () { return clearTimeout(debounceTimer_1); };
        }
        else {
            setCompanies([]);
            setShowDropdown(false);
        }
    }, [searchQuery, selectedCompany]);
    var handleInputChange = function (e) {
        var value = e.target.value;
        setSearchQuery(value);
        setSelectedCompany(null);
    };
    var handleSelect = function (company) {
        setSelectedCompany(company);
        onCompanySelect(company);
        setSearchQuery(company.name);
        setShowDropdown(false);
        if (inputRef.current) {
            inputRef.current.blur();
        }
    };
    return (react_1.default.createElement("div", { className: "relative" },
        react_1.default.createElement("input", { ref: inputRef, type: "text", value: searchQuery, onChange: handleInputChange, onFocus: function () { return !selectedCompany && setShowDropdown(true); }, placeholder: "Rechercher une entreprise...", className: "w-full p-2 border rounded", autoComplete: "nope" }),
        loading && react_1.default.createElement(flowbite_react_1.Spinner, { size: "sm", className: "absolute right-2 top-2" }),
        showDropdown && !selectedCompany && (react_1.default.createElement("ul", { className: "absolute z-10 w-full bg-white border rounded mt-1 max-h-60 overflow-auto" }, companies.map(function (company) { return (react_1.default.createElement("li", { key: company.id, onClick: function () { return handleSelect(company); }, className: "p-2 hover:bg-gray-100 cursor-pointer" },
            company.name,
            " (",
            company.siren,
            ")")); })))));
};
exports.default = CompanySearch;
